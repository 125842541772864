import React, { useState, useRef } from "react";
import emailjs from 'emailjs-com';

const ContactForm = () => {
  
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_6uojl3y', 'template_4w9njhy', form.current, 'QftHQtwIFZbcB2KoA')
      .then((result) => {
        setSubmitted(true);
      }, (error) => {
        setError(
          <div className="FormElement FormElement--Error">
            Erreur lors de l'envoi de l'e-mail.
          </div>
        );
      });
  };

  if (submitted) {
    return (
      <>
        <p><strong>Merci !</strong></p>
        <p>L'Atelier Miix vous répondra dès que possible.</p>
      </>
    );
  }

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
    >
      {error}
      <div className="FormElement FormElement--Input">
        <input type="text" placeholder="Votre nom" name="from_name" required />
      </div>
      <div className="FormElement FormElement--Input">
        <input type="email" placeholder="Votre adresse e-mail" name="from_email" required />
      </div>
      <div className="FormElement FormElement--Input">
        <textarea placeholder="Votre message pour l'Atelier Miix" name="message" required />
      </div>
      <div className="FormElement FormElement--Message">
        Votre message sera envoyé sur notre adresse e-mail et nous vous répondrons dès que possible.
      </div>
      <div className="FormElement FormElement--Submit">
        <button type="submit">Envoyer votre message</button>
      </div>
    </form>
  );
};

export default ContactForm;