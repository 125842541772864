import background from './assets/images/background.png';
import backgroundMobile from './assets/images/background-mobile.png';
import instagramLogo from './assets/images/instagram.svg';
import marieFelix from "./assets/images/marie-felix.jpg";
import './App.css';
import Modal from 'react-modal';
import ContactForm from "./ContactForm";
import { useState } from 'react';

function App() {

  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);

  const setContactModalIsOpenToTrue = () => {
    setContactModalIsOpen(true)
  }
  const setContactModalIsOpenToFalse = () => {
    setContactModalIsOpen(false)
  }

  const setAboutModalIsOpenToTrue = () => {
    setAboutModalIsOpen(true)
  }
  const setAboutModalIsOpenToFalse = () => {
    setAboutModalIsOpen(false)
  }

  return (
    <>
      <div className="AppBackground"></div>
      <div className="App">
        <header>
          <h1>Atelier<span className="VisuallyHidden"> Miix</span></h1>
        </header>
        <Modal
          isOpen={contactModalIsOpen}
          ariaHideApp={false}
          contentLabel={"Formulaire de contact"}
          id={"contact-form-modal"}
          style={{
            overlay: {
              zIndex: 1000
            }
          }}
          closeTimeoutMS={150}
        >
          <div className="ModalContent-Header">
            <h2>Contactez-nous !</h2>
            <button onClick={setContactModalIsOpenToFalse}>×</button>
          </div>
          <ContactForm />
        </Modal>
        <Modal
          isOpen={aboutModalIsOpen}
          ariaHideApp={false}
          contentLabel={"À propos"}
          id={"about-form-modal"}
          style={{
            overlay: {
              zIndex: 1000
            }
          }}
          closeTimeoutMS={150}
        >
          <div className="ModalContent-Header">
            <h2>À propos de nous</h2>
            <button onClick={setAboutModalIsOpenToFalse}>×</button>
          </div>
          <p>
            <img src={marieFelix} alt="Portrait de Marie Grimaud et de Félix Albert" className="AboutPortrait" />
          </p>
          <h4>Atelier Miix</h4>
          <p>Marie Grimaud et Félix Albert<br />75 007 Paris</p>
          <h4>Hébergement</h4>
          <p>Ce site Internet est hébergé par OVH<br />2 rue Kellermann, 59100 Roubaix, France</p>
          <h4>Responsable de la publication</h4>
          <p>Marie Grimaud</p>
          <h4>Données personnelles</h4>
          <p>D'une façon générale, vous pouvez visiter notre site sur Internet sans avoir à décliner votre identité ni à fournir des informations personnelles vous concernant. Cependant, nous pouvons vous demander des informations comme votre nom et votre email pour établir une correspondancevia le formulaire de contact.</p>
        </Modal>
        <main>
          <div className="Background">
            <img className="BackgroundImage" src={background} alt="Background" />
            <img className="BackgroundImageMobile" src={backgroundMobile} alt="Background Mobile" />
          </div>
          <div className="Content">
            <h2>Joailliers</h2>
            <h3>Artisans . Designers</h3>
            <h4>Marie Grimaud et Félix Albert</h4>
            <p>
              Paris / Bordeaux
            </p>
            <p class="Footer">
              <button className="ContactButton" onClick={setContactModalIsOpenToTrue}>Contactez-nous</button><span>‧</span>
              <button className="AboutButton" onClick={setAboutModalIsOpenToTrue}>À propos</button><span>‧</span>
              <a href="https://www.instagram.com/atelier_miix/" rel="noreferrer" target="_blank" title="Page Instagram de l'Atelier Miix" className="Instagram">
                <img src={instagramLogo} alt="Logo Instagram" height="auto" width="20" />
              </a>
            </p>
          </div>
        </main>
      </div>
    </>
  );
}

export default App;
